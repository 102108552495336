/* eslint-disable */
// import React from 'react';
// import parseQueryString from '../../utility/parseQueryString';

// const SENSOR_WEB_URL_PRODUCTION =
//   'https://bellcode.cloud.sensorsdata.cn/?project=production';
// const SENSOR_DATA_URL_PRODUCTION =
//   'https://bellcode.cloud.sensorsdata.cn:4006/sa?token=380824581b13c7c1&project=production';

// const SENSOR_WEB_URL_DEV = 'https://bellcode.cloud.sensorsdata.cn/';
// const SENSOR_DATA_URL_DEV =
//   'http://bellcode.cloud.sensorsdata.cn:8006/sa?token=380824581b13c7c1';

// var sensorDataWebUrl, sensorDataServerUrl;
// class TrackScript extends React.Component {
//   constructor(props) {
//     super(props);
//     setTimeout(this.getSensorData, 0);

//     var productionHost = 'bellcode.com';
//     var currentUrl = window.location.hostname.toLowerCase();

//     if (currentUrl.search(productionHost) < 0) {
//       sensorDataWebUrl = SENSOR_WEB_URL_DEV;
//       sensorDataServerUrl = SENSOR_DATA_URL_DEV;
//     } else {
//       sensorDataWebUrl = SENSOR_WEB_URL_PRODUCTION;
//       sensorDataServerUrl = SENSOR_DATA_URL_PRODUCTION;
//     }
//   }

//   getSensorData() {
//     (function(para) {
//       var p = para.sdk_url,
//         n = para.name,
//         w = window,
//         d = document,
//         s = 'script',
//         x = null,
//         y = null;
//       w['sensorsDataAnalytic201505'] = n;
//       w[n] =
//         w[n] ||
//         function(a) {
//           return function() {
//             (w[n]._q = w[n]._q || []).push([a, arguments]);
//           };
//         };
//       var ifs = [
//         'track',
//         'quick',
//         'register',
//         'registerPage',
//         'registerOnce',
//         'trackSignup',
//         'trackAbtest',
//         'setProfile',
//         'setOnceProfile',
//         'appendProfile',
//         'incrementProfile',
//         'deleteProfile',
//         'unsetProfile',
//         'identify',
//         'login',
//         'logout',
//         'trackLink',
//         'clearAllRegister',
//         'getAppStatus',
//       ];
//       for (var i = 0; i < ifs.length; i++) {
//         w[n][ifs[i]] = w[n].call(null, ifs[i]);
//       }
//       if (!w[n]._t) {
//         (x = d.createElement(s)), (y = d.getElementsByTagName(s)[0]);
//         x.async = 1;
//         x.src = p;
//         x.setAttribute('charset', 'UTF-8');
//         y.parentNode.insertBefore(x, y);
//         w[n].para = para;
//       }
//     })({
//       sdk_url: '//static.sensorsdata.cn/sdk/1.10.7/sensorsdata.min.js',
//       heatmap_url: '//static.sensorsdata.cn/sdk/1.10.7/heatmap.min.js',
//       name: 'sa',
//       web_url: sensorDataWebUrl,
//       server_url: sensorDataServerUrl,
//       heatmap: {},
//       show_log: __PRO__ ? false : true,
//     });

//     sa.quick('autoTrack', {
//       ...parseQueryString(location.search),
//     });
//   }

//   render() {
//     return null;
//   }
// }

function TrackScript(props) {
  if(!__PRO__) {
    console.log(`[TrackScript]`, props);
  }
  return null;
}

export default TrackScript;
