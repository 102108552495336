import { useEffect } from 'react';
// import UserData from 'PublicRepo/utility/userDataManager';
// import getEventEmitterSingleton from 'PublicRepo/utility/getEventEmitterSingleton';
import checkIsInWebView from 'PublicRepo/utility/checkIsInWebView';
import IntercomHandler, {
  updateScriptArgs,
} from 'PublicRepo/components/Intercom/intercomHandler';
import { isMobile } from 'PublicRepo/lib/utilities';

const initZHICHI = () => {
  const s = document.createElement('script');
  const scriptTag = document.getElementsByTagName('script')[0];
  s.async = true;
  s.charset = 'UTF-8';
  s.setAttribute('data-args', updateScriptArgs());
  s.id = 'zhichiScript';
  s.className = 'zhiCustomBtn';
  s.src = 'https://www.sobot.com/chat/frame/js/entrance.js?sysNum=ece1287103f04a20a5eb7312b7235a45';
  s.onload = function () {
    IntercomHandler.setZhiScriptLoaded();
  };
  scriptTag.parentNode.insertBefore(s, scriptTag);
};

function IntercomScript() {
  useEffect(() => {
    if (!isMobile() && !checkIsInWebView) {
      setTimeout(initZHICHI);
    }
  }, []);
  return null;
}

// class IntercomScript extends Component {
//   constructor(props) {
//     super(props);
//     if (!isMobile() && !checkIsInWebView) {
//       setTimeout(this.initZHICHI, 0);
//     }
//   }

//   initZHICHI() {
//     const s = document.createElement('script');
//     const scriptTag = document.getElementsByTagName('script')[0];
//     s.async = true;
//     s.charset = 'UTF-8';
//     s.setAttribute('data-args', updateScriptArgs());
//     s.id = 'zhichiScript';
//     s.className = 'zhiCustomBtn';
//     s.src =
//       'https://www.sobot.com/chat/frame/js/entrance.js?sysNum=ece1287103f04a20a5eb7312b7235a45';
//     s.onload = function() {
//       IntercomHandler.setZhiScriptLoaded();
//     };
//     scriptTag.parentNode.insertBefore(s, scriptTag);
//   }

//   render() {
//     return null;
//   }
// }

export default IntercomScript;
