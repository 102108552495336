// Maybe move this file into playground/index ?

import React from 'react';
import {render} from 'react-dom';
import UserData from 'PublicRepo/utility/userDataManager';
import TrackScript from 'PublicRepo/components/Track/TrackScript';
import IntercomScript from 'PublicRepo/components/Intercom/intercom';
import 'PublicRepo/utility/webview-bridge.js';

// import UpscopeScript from 'PublicRepo/components/Upscope/upscope';
// import ErrTracker from 'PublicRepo/components/errTracker/errTracker';

const appTarget = document.createElement('div');
document.body.appendChild(appTarget);

UserData.initUserInfo();
render([
    <IntercomScript key="IntercomScript" />,
    // <UpscopeScript key="UpscopeScript" />,
    <TrackScript key="TrackScript" />
    /* 错误报告插件 */
    // <ErrTracker key="ErrTracker" />
], appTarget);
